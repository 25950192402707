import { createSelector } from "reselect";
import { AppState } from "..";
import { REFLECTION_CATEGORY_TYPE } from "../../enums";

const getReflectionState = (state: AppState) => state.reflection;

export const keeplerMessageSelector = createSelector([getReflectionState], (reflection) => reflection.messages);

export const categorySelector = createSelector([getReflectionState], (reflection) => reflection.categories);

export const presentCategoriesSelector = createSelector(
  [categorySelector],
  ({ items }) => items.filter((item) => item.type === REFLECTION_CATEGORY_TYPE.PRESENT) || []
);

export const valuesCategoriesSelector = createSelector(
  [categorySelector],
  ({ items }) => items.filter((item) => item.type === REFLECTION_CATEGORY_TYPE.VALUE) || []
);

export const alignmentCategoriesSelector = createSelector(
  [categorySelector],
  ({ items }) => items.filter((item) => item.type === REFLECTION_CATEGORY_TYPE.ALIGNMENT) || []
);

export const intentionCategoriesSelector = createSelector(
  [categorySelector],
  ({ items }) => items.filter((item) => item.type === REFLECTION_CATEGORY_TYPE.INTENTION) || []
);

export const selfCategoriesSelector = createSelector(
  [categorySelector],
  ({ items }) => items.filter((item) => item.type === REFLECTION_CATEGORY_TYPE.SELF) || []
);

export const beliefCategoriesSelector = createSelector(
  [categorySelector],
  ({ items }) => items.filter((item) => item.type === REFLECTION_CATEGORY_TYPE.BELIEF) || []
);

export const lessonCategoriesSelector = createSelector(
  [categorySelector],
  ({ items }) => items.filter((item) => item.type === REFLECTION_CATEGORY_TYPE.LESSON) || []
);

export const inspirationCategoriesSelector = createSelector(
  [categorySelector],
  ({ items }) => items.filter((item) => item.type === REFLECTION_CATEGORY_TYPE.INSPIRATION) || []
);

export const affirmationCategoriesSelector = createSelector(
  [categorySelector],
  ({ items }) => items.filter((item) => item.type === REFLECTION_CATEGORY_TYPE.AFFIRMATION) || []
);

export const gratitudeCategoriesSelector = createSelector(
  [categorySelector],
  ({ items }) => items.filter((item) => item.type === REFLECTION_CATEGORY_TYPE.GRATITUDE) || []
);

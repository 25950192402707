import { all, call, fork, put, take } from "redux-saga/effects";
import { fetchTemplatesApi } from "../../apiService";

import { FAIL, START, SUCCESS } from "../common";
import { FETCH_TEMPLATES, TemplatePayload } from "./actions";

function* getTemplates() {
  while (true) {
    yield take(FETCH_TEMPLATES + START);
    try {
      const data: TemplatePayload = yield call(fetchTemplatesApi);
      yield put({ type: FETCH_TEMPLATES + SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: FETCH_TEMPLATES + FAIL, payload: error.response.data });
    }
  }
}

export function* templateSagas() {
  yield all([fork(getTemplates)]);
}

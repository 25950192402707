export enum REFLECTION_TYPE {
  MESSAGE = "keepler_message",
  TODAY = "reflection_today",
  PAST = "reflection_past",
  ARTICLE = "keepler_article",
}

export enum REFLECTION_CATEGORY_TYPE {
  PRESENT = "present moment",
  VALUE = "values",
  SELF = "self discovery",
  INTENTION = "intention",
  BELIEF = "beliefs",
  ALIGNMENT = "alignment",
  LESSON = "lessons learned",
  INSPIRATION = "inspiration",
  AFFIRMATION = "affirmations",
  GRATITUDE = "gratitude",
  ACTION = "action",
}

export enum INPUT_OPTION {
  TEXT = "text",
  VOICE = "voice",
  OPTION = "option",
  MULTIPLE = "multiple-option",
  IMAGE = "image",
  VIDEO = "video",
  SLIDER = "slider",
  LIST = "list",
}

import React from "react";
import { Avatar, CircularProgress, Container } from "@material-ui/core";
import { LOGO } from "../assets/svgs";

export const AppLoader = () => {
  return (
    <Container className="h-full justify-center flex items-center">
      <Avatar src={LOGO} className="h-36 w-36 absolute" />
      <CircularProgress size={150} color="secondary" thickness={0.5} />
    </Container>
  );
};

import { createAction } from "redux-actions";
import { User } from "../../interfaces";
import { START } from "../common";

export const UPDATE_SESSION = "UPDATE_SESSION";
export const FETCH_LOGIN = "FETCH_LOGIN";
export const FETCH_USER = "FETCH_USER";
export const LOG_OUT = "LOG_OUT";

interface FetchLoginPayload {
  email: string;
  password: string;
}
interface FetchLoginSuccessPayload {
  token: string;
  user: User;
}

export type SystemPayload = FetchLoginPayload | FetchLoginSuccessPayload;

export const fetchLogin = createAction<SystemPayload>(FETCH_LOGIN + START);
export const fetchUser = createAction(FETCH_USER + START);
export const logOut = createAction(LOG_OUT);

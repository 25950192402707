import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikActions, FormikProps } from "formik";
import { Redirect } from "react-router";

import { Button, CircularProgress, Theme, WithStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

import { AppState } from "../../store";
import { fetchLogin, SystemPayload } from "../../store/system/actions";
import { LOGO } from "../../assets/svgs";

const styles = (theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  });

interface LogInPageStateProps {
  loading: boolean;
  loggedIn: boolean;
  error: string;
}

interface LogInPageDispatchProps {
  fetchLogin: (payload: SystemPayload) => void;
}

interface LogInPageOwnProps {}

interface MyFormValues {
  email: string;
  password: string;
}

type LogInPageProps = LogInPageStateProps & LogInPageDispatchProps & LogInPageOwnProps & WithStyles<typeof styles>;

class LogInPage extends React.Component<LogInPageProps> {
  constructor(props: any) {
    super(props);
    this.onFormikSubmit = this.onFormikSubmit.bind(this);
  }

  async onFormikSubmit(values: MyFormValues, { setSubmitting }: FormikActions<MyFormValues>) {
    if (values.email !== "" && values.password !== "" && !this.props.loading) {
      this.props.fetchLogin({ ...values });
    }
  }

  render() {
    const { classes, loggedIn, loading, error } = this.props;
    if (loggedIn) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className="mb-16 w-36 h-36">
            <img src={LOGO} alt="Keepler" className="w-36 h-36" />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <Formik
            initialValues={{
              password: "",
              email: "",
            }}
            onSubmit={this.onFormikSubmit}
            render={(formikBag: FormikProps<MyFormValues>) => (
              <form className={classes.form} noValidate onSubmit={formikBag.handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={formikBag.handleChange}
                  onBlur={formikBag.handleBlur}
                  value={formikBag.values.email}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={formikBag.handleChange}
                  onBlur={formikBag.handleBlur}
                  value={formikBag.values.password}
                />
                {error && !loading && <Alert severity="error">{error}</Alert>}
                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                  {!loading && "Sign In"}
                  {loading && <CircularProgress color="inherit" className="w-6 h-6" />}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ system }: AppState) => ({
  loggedIn: system.loggedIn,
  loading: system.loading,
  error: system.error?.message,
});

const mapDispatchToProps = {
  fetchLogin,
};

export default withStyles(styles)(
  connect<LogInPageStateProps, LogInPageDispatchProps, LogInPageOwnProps, AppState>(
    mapStateToProps,
    mapDispatchToProps
  )(LogInPage)
);

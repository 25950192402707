import { createAction } from "redux-actions";
import { User } from "../../interfaces";
import { START } from "../common";

export const FETCH_USERS = "FETCH_USERS";

interface FetchUsersPayload {
  page: number;
  limit: number;
}
interface FetchUsersSuccessPayload {
  data: Array<User>;
  page: number;
  limit: number;
  total: number;
}

export type UsersPayload = FetchUsersPayload | FetchUsersSuccessPayload;

export const fetchUsers = createAction(FETCH_USERS + START);

import produce from "immer";
import { handleActions } from "redux-actions";

import { SystemState } from "../../interfaces";
import { FAIL, START, SUCCESS } from "../common";
import { FETCH_LOGIN, FETCH_USER, LOG_OUT } from "./actions";

const initialState: SystemState = {
  loggedIn: false,
  user: undefined,
  loading: false,
  error: undefined,
  authorizing: false,
};

export const systemReducer = handleActions<SystemState, any>(
  {
    [FETCH_LOGIN + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = undefined;
      }),
    [FETCH_LOGIN + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loggedIn = true;
        draft.error = undefined;
        draft.user = payload.user;
        draft.authorizing = false;
      }),
    [FETCH_LOGIN + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loggedIn = false;
        draft.error = payload;
        draft.user = undefined;
      }),
    [FETCH_USER + START]: (state) =>
      produce(state, (draft) => {
        draft.authorizing = true;
      }),
    [FETCH_USER + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.loggedIn = true;
        draft.error = undefined;
        draft.authorizing = false;
        draft.user = payload;
      }),
    [FETCH_USER + FAIL]: (state) =>
      produce(state, (draft) => {
        draft.authorizing = false;
        draft.loggedIn = false;
      }),
    [LOG_OUT]: () => initialState,
  },
  initialState
);

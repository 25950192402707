import { all, call, fork, put, take } from "redux-saga/effects";
import {
  fetchMessagesApi,
  createMessagesApi,
  deleteMessagesApi,
  updateMessagesApi,
  fetchCategoriesApi,
} from "../../apiService";
import { KeeplerMessage, Category } from "../../interfaces";

import { FAIL, START, SUCCESS } from "../common";
import {
  FETCH_KEEPLER_MESSAGES,
  CREATE_KEEPLER_MESSAGE,
  RESET_ITEM_STATUS,
  DELETE_KEEPLER_MESSAGE,
  UPDATE_KEEPLER_MESSAGE,
  FETCH_REFLECTION_CATEGORY,
} from "./actions";

function* fetchMessages() {
  while (true) {
    yield take(FETCH_KEEPLER_MESSAGES + START);
    try {
      const data: { data: [KeeplerMessage] } = yield call(fetchMessagesApi);
      yield put({ type: FETCH_KEEPLER_MESSAGES + SUCCESS, payload: data });
    } catch (error) {
      yield put({
        type: FETCH_KEEPLER_MESSAGES + FAIL,
        payload: error.response.data,
      });
    }
  }
}

function* createMessage() {
  while (true) {
    const { payload } = yield take(CREATE_KEEPLER_MESSAGE + START);
    try {
      const data: { data: KeeplerMessage } = yield call(createMessagesApi, payload);
      yield put({ type: CREATE_KEEPLER_MESSAGE + SUCCESS, payload: data });
    } catch (error) {
      yield put({
        type: CREATE_KEEPLER_MESSAGE + FAIL,
        payload: error.response.data,
      });
    }
    yield put({ type: FETCH_KEEPLER_MESSAGES + START });
    yield put({ type: RESET_ITEM_STATUS });
  }
}

function* deleteMessage(): any {
  while (true) {
    const { payload } = yield take(DELETE_KEEPLER_MESSAGE + START);
    try {
      const data = yield call(deleteMessagesApi, payload);
      yield put({ type: DELETE_KEEPLER_MESSAGE + SUCCESS, payload: data });
    } catch (error) {
      yield put({
        type: DELETE_KEEPLER_MESSAGE + FAIL,
        payload: error.response.data,
      });
    }
    yield put({ type: RESET_ITEM_STATUS });
  }
}

function* updateMessage(): any {
  while (true) {
    const {
      payload: { id, message },
    } = yield take(UPDATE_KEEPLER_MESSAGE + START);
    try {
      const data = yield call(updateMessagesApi, id, message);
      yield put({ type: UPDATE_KEEPLER_MESSAGE + SUCCESS, payload: data });
    } catch (error) {
      yield put({
        type: UPDATE_KEEPLER_MESSAGE + FAIL,
        payload: error.response.data,
      });
    }
    yield put({ type: RESET_ITEM_STATUS });
  }
}

function* fetchCategories() {
  while (true) {
    yield take(FETCH_REFLECTION_CATEGORY + START);
    try {
      const data: { data: [Category] } = yield call(fetchCategoriesApi);
      yield put({ type: FETCH_REFLECTION_CATEGORY + SUCCESS, payload: data });
    } catch (error) {
      yield put({
        type: FETCH_REFLECTION_CATEGORY + FAIL,
        payload: error.response.data,
      });
    }
  }
}

export function* reflectionSagas() {
  yield all([
    fork(fetchMessages),
    fork(createMessage),
    fork(deleteMessage),
    fork(updateMessage),
    fork(fetchCategories),
  ]);
}

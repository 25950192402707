import * as React from "react";
import PermMediaOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActual";
import PeopleIcon from "@material-ui/icons/People";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import SettingsIcon from "@material-ui/icons/Settings";
import { NavigatorGroup } from "../components/Navigator";

export const navigation: Array<NavigatorGroup> = [
  {
    id: "overview",
    title: "Oveview",
    entries: [
      {
        id: "overview.users",
        title: "Users",
        icon: <PeopleIcon />,
        href: "/users",
      },
      { id: "overview.reflection", title: "Reflections", icon: <DnsRoundedIcon />, href: "/reflections" },
      { id: "overview.template", title: "Templates", icon: <PermMediaOutlinedIcon />, href: "/templates" },
    ],
  },
  {
    id: "setting",
    title: "Settings",
    entries: [
      { id: "setting.analytics", title: "Analytics", icon: <SettingsIcon />, href: "/setting/analytics" },
      { id: "setting.other1", title: "Other1", icon: <SettingsIcon />, href: "/setting/analytics" },
      { id: "setting.other2", title: "Other2", icon: <SettingsIcon />, href: "/setting/analytics" },
      { id: "setting.other3", title: "Other3", icon: <SettingsIcon />, href: "/setting/analytics" },
    ],
  },
];

import produce from "immer";
import { handleActions } from "redux-actions";

import { ReflectionState } from "../../interfaces";
import { FAIL, START, SUCCESS } from "../common";
import {
  CREATE_KEEPLER_MESSAGE,
  DELETE_KEEPLER_MESSAGE,
  FETCH_KEEPLER_MESSAGES,
  RESET_ITEM_STATUS,
  UPDATE_KEEPLER_MESSAGE,
  FETCH_REFLECTION_CATEGORY,
} from "./actions";

const initialState: ReflectionState = {
  messages: {
    items: [],
    loading: false,
    error: null,
    itemStatus: {
      loading: false,
      loaded: false,
      mode: undefined,
      itemId: undefined,
    },
  },
  categories: {
    items: [],
    loading: false,
    error: null,
    itemStatus: {
      loading: false,
      loaded: false,
      mode: undefined,
      itemId: undefined,
    },
  },
};

export const reflectionReducer = handleActions<ReflectionState, any>(
  {
    [FETCH_KEEPLER_MESSAGES + START]: (state) =>
      produce(state, (draft) => {
        draft.messages.loading = true;
        draft.messages.error = null;
      }),
    [FETCH_KEEPLER_MESSAGES + SUCCESS]: (state, { payload: { data } }) =>
      produce(state, (draft) => {
        draft.messages.loading = false;
        draft.messages.items = data;
        draft.messages.error = null;
      }),
    [FETCH_KEEPLER_MESSAGES + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.loading = false;
        draft.messages.error = payload;
      }),
    [CREATE_KEEPLER_MESSAGE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = true;
        draft.messages.itemStatus.loaded = false;
        draft.messages.itemStatus.mode = "create";
      }),
    [CREATE_KEEPLER_MESSAGE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = false;
        draft.messages.itemStatus.loaded = true;
      }),
    [CREATE_KEEPLER_MESSAGE + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = false;
        draft.messages.itemStatus.mode = undefined;
        draft.messages.error = payload;
      }),
    [DELETE_KEEPLER_MESSAGE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = true;
        draft.messages.itemStatus.loaded = false;
        draft.messages.itemStatus.mode = "delete";
        draft.messages.itemStatus.itemId = payload;
      }),
    [DELETE_KEEPLER_MESSAGE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = false;
        draft.messages.itemStatus.loaded = true;
        draft.messages.items = draft.messages.items.filter((item) => item.id !== payload.id);
      }),
    [DELETE_KEEPLER_MESSAGE + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = false;
        draft.messages.itemStatus.mode = undefined;
        draft.messages.error = payload;
      }),
    [DELETE_KEEPLER_MESSAGE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = true;
        draft.messages.itemStatus.loaded = false;
        draft.messages.itemStatus.mode = "delete";
        draft.messages.itemStatus.itemId = payload;
      }),
    [DELETE_KEEPLER_MESSAGE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = false;
        draft.messages.itemStatus.loaded = true;
        draft.messages.items = draft.messages.items.filter((item) => item.id !== payload.id);
      }),
    [DELETE_KEEPLER_MESSAGE + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = false;
        draft.messages.itemStatus.mode = undefined;
        draft.messages.error = payload;
      }),
    [UPDATE_KEEPLER_MESSAGE + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = true;
        draft.messages.itemStatus.loaded = false;
        draft.messages.itemStatus.mode = "update";
        draft.messages.itemStatus.itemId = payload.id;
      }),
    [UPDATE_KEEPLER_MESSAGE + SUCCESS]: (state, { payload: { data } }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = false;
        draft.messages.itemStatus.loaded = true;
        draft.messages.items = draft.messages.items.map((item) => (item.id === data.id ? data : item));
      }),
    [UPDATE_KEEPLER_MESSAGE + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.messages.itemStatus.loading = false;
        draft.messages.itemStatus.mode = undefined;
        draft.messages.error = payload;
      }),
    [RESET_ITEM_STATUS]: (state) =>
      produce(state, (draft) => {
        draft.messages.itemStatus = initialState.messages.itemStatus;
      }),
    [FETCH_REFLECTION_CATEGORY + START]: (state) =>
      produce(state, (draft) => {
        draft.categories.loading = true;
        draft.categories.error = null;
      }),
    [FETCH_REFLECTION_CATEGORY + SUCCESS]: (state, { payload: { data } }) =>
      produce(state, (draft) => {
        draft.categories.loading = false;
        draft.categories.items = data;
        draft.categories.error = null;
      }),
    [FETCH_REFLECTION_CATEGORY + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.categories.loading = false;
        draft.categories.error = payload;
      }),
  },
  initialState
);

import React from "react";
import PropTypes, { InferProps } from "prop-types";
import { useSelector } from "react-redux";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import { InputOptions } from "../../../../components";

import { beliefCategoriesSelector } from "../../../../store/reflection/selector";

const columns: GridColDef[] = [
  {
    field: "prompt",
    headerName: "Prompt",
    flex: 0.7,
    renderCell: (params) => <Typography className="text-sm font-medium">{params.row.prompt}</Typography>,
  },
  {
    field: "inputOptions",
    headerName: "Input Options",
    width: 180,
    renderCell: (params) => <InputOptions items={params.row.inputOptions} />,
  },
  {
    field: "sharable",
    headerName: "Sharable",
    width: 180,
    renderCell: (params) => (params.row.sharable ? <CheckIcon color="primary" /> : <></>),
  },
];

export function BeliefTab({ active }: InferProps<typeof BeliefTab.propTypes>) {
  const items = useSelector(beliefCategoriesSelector);
  return (
    <div className={`${active ? "flex" : " hidden"} h-full p-2`}>
      <DataGrid columns={columns} rows={items} hideFooter disableSelectionOnClick={true} />
    </div>
  );
}

BeliefTab.propTypes = {
  active: PropTypes.bool.isRequired,
};

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes, { InferProps } from "prop-types";
import { Card, Tabs, Tab } from "@material-ui/core";

import { REFLECTION_CATEGORY_TYPE } from "../../../../enums";
import { PresentTab } from "./PresentTab";
import { ValuesTab } from "./ValuesTab";
import { AlignmentTab } from "./AlignmentTab";
import { IntentionTab } from "./IntentionTab";
import { SelfTab } from "./SelfTab";

import { fetchReflectionCategory } from "../../../../store/reflection/actions";
import { BeliefTab } from "./BeliefTab";
import { LessonTab } from "./LessonTab";
import { InspirationTab } from "./InspirationTab";
import { AffirmationTab } from "./AffirmationTab";
import { GratitudeTab } from "./GratitudeTab";

const tabs = [
  { index: 0, title: "Present Moment", type: REFLECTION_CATEGORY_TYPE.PRESENT },
  { index: 1, title: "Values", type: REFLECTION_CATEGORY_TYPE.VALUE },
  { index: 2, title: "Alignment", type: REFLECTION_CATEGORY_TYPE.ALIGNMENT },
  { index: 3, title: "Intentions", type: REFLECTION_CATEGORY_TYPE.INTENTION },
  { index: 4, title: "Self", type: REFLECTION_CATEGORY_TYPE.SELF },
  { index: 5, title: "Beliefs", type: REFLECTION_CATEGORY_TYPE.BELIEF },
  { index: 6, title: "Lessons Learned", type: REFLECTION_CATEGORY_TYPE.LESSON },
  { index: 7, title: "Inspiration", type: REFLECTION_CATEGORY_TYPE.INSPIRATION },
  { index: 8, title: "Affirmations", type: REFLECTION_CATEGORY_TYPE.AFFIRMATION },
  { index: 9, title: "Gratitude", type: REFLECTION_CATEGORY_TYPE.GRATITUDE },
];

export function CategoryTab({ active }: InferProps<typeof CategoryTab.propTypes>) {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    dispatch(fetchReflectionCategory());
  }, [dispatch]);

  return (
    <Card role="tabpanel" className={`${active ? "flex" : "hidden"} flex-1 flex-col m-2`}>
      <Tabs
        value={tabIndex}
        onChange={(event: any, value) => setTabIndex(value)}
        className="bg-blue-800 text-white ml-0 w-full"
      >
        {tabs.map((tab) => (
          <Tab key={tab.index} label={tab.title} />
        ))}
      </Tabs>
      <div className="flex flex-col flex-1 w-full">
        <PresentTab active={tabIndex === 0} />
        <ValuesTab active={tabIndex === 1} />
        <AlignmentTab active={tabIndex === 2} />
        <IntentionTab active={tabIndex === 3} />
        <SelfTab active={tabIndex === 4} />
        <BeliefTab active={tabIndex === 5} />
        <LessonTab active={tabIndex === 6} />
        <InspirationTab active={tabIndex === 7} />
        <AffirmationTab active={tabIndex === 8} />
        <GratitudeTab active={tabIndex === 9} />
      </div>
    </Card>
  );
}

CategoryTab.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

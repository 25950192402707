import { createAction } from "redux-actions";
import { Template } from "../../interfaces";
import { START } from "../common";

export const FETCH_TEMPLATES = "FETCH_TEMPLATES";

interface FetchTemplateSuccessPayload {
  data: Array<Template>;
}

export type TemplatePayload = FetchTemplateSuccessPayload;

export const fetchTemplates = createAction(FETCH_TEMPLATES + START);

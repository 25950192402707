import produce from "immer";
import { handleActions } from "redux-actions";

import { TemplateState } from "../../interfaces";
import { FAIL, START, SUCCESS } from "../common";
import { FETCH_TEMPLATES } from "./actions";

const initialState: TemplateState = {
  items: [],
  loading: false,
  error: null,
};

export const templateReducer = handleActions<TemplateState, any>(
  {
    [FETCH_TEMPLATES + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
      }),
    [FETCH_TEMPLATES + SUCCESS]: (state, { payload: { data } }) =>
      produce(state, (draft) => {
        draft.items = data;
        draft.loading = false;
        draft.error = null;
      }),
    [FETCH_TEMPLATES + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
  },
  initialState
);

import { all, call, fork, put, take } from "redux-saga/effects";
import { fetchLoginApi, fetchUserApi } from "../../apiService";
import { removeAuthToken, setAuthToken } from "../../config";
import { User } from "../../interfaces";

import { FAIL, START, SUCCESS } from "../common";
import { FETCH_LOGIN, FETCH_USER, LOG_OUT } from "./actions";

interface LoginResponse {
  token: string;
  user: User;
}

function* login() {
  while (true) {
    const { payload } = yield take(FETCH_LOGIN + START);
    try {
      const data: LoginResponse = yield call(fetchLoginApi, payload.email, payload.password);
      setAuthToken(data.token);
      yield put({ type: FETCH_LOGIN + SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: FETCH_LOGIN + FAIL, payload: error.response.data });
    }
  }
}

function* getUser() {
  while (true) {
    yield take(FETCH_USER + START);
    try {
      const data: User = yield call(fetchUserApi);
      yield put({ type: FETCH_USER + SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: FETCH_USER + FAIL, payload: error.response.data });
    }
  }
}

function* logout() {
  while (true) {
    yield take(LOG_OUT);
    yield removeAuthToken();
  }
}

export function* appSagas() {
  yield all([fork(login), fork(getUser), fork(logout)]);
}

import React from "react";
import { Redirect, Route, Switch } from "react-router";

import { List } from "./List";

const UserRouter = () => {
  return (
    <Switch>
      <Route exact path="/users" component={List} />
      <Redirect to="/users" />
    </Switch>
  );
};

export default UserRouter;

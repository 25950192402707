import React from "react";
import { Route, Redirect } from "react-router";

import { Typography } from "@material-ui/core";

import AdminHtml from "../layouts/AdminHtml";
import LogOutPage from "../pages/LogOutPage";
import HomePage from "../pages/HomePage";
import UserRouter from "../pages/UserPage";
import TemplateRouter from '../pages/TemplatePage';
import { ReflectionTabs } from "../pages/ReflectionPage/ReflectionTabs";
import { Switch } from "react-router-dom";

const PrivateRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/logout" component={LogOutPage} />
      <Route exact path="/users">
        <AdminHtml pageTitle="Users">
          <UserRouter />
        </AdminHtml>
      </Route>
      <Route exact path="/reflections">
        <AdminHtml pageTitle="Reflections">
          <ReflectionTabs />
        </AdminHtml>
      </Route>
      <Route exact path="/templates">
        <AdminHtml pageTitle="Templates">
          <TemplateRouter />
        </AdminHtml>
      </Route>
      <Route exact path="/setting/analytics">
        <AdminHtml pageTitle="Analytics">
          <Typography component="h1">Space for Content in Analytics</Typography>
        </AdminHtml>
      </Route>
      <Route path="/dashboard" exact>
        <AdminHtml pageTitle="Dashboard">
          <HomePage />
        </AdminHtml>
      </Route>
      <Route component={() => <Redirect to="/dashboard" />} />
    </Switch>
  );
};

export default PrivateRouter;

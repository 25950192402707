import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes, { InferProps } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { createKeeplerMessage } from "../store/reflection/actions";
import { keeplerMessageSelector } from "../store/reflection/selector";
import { CircularProgress } from "@material-ui/core";

export function CreateMessageDialog({
  visible,
  onConfirm,
  onCancel,
}: InferProps<typeof CreateMessageDialog.propTypes>) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const {
    itemStatus: { loading, loaded },
  } = useSelector(keeplerMessageSelector);
  useEffect(() => {
    if (visible) {
      setMessage("");
    }
  }, [visible]);
  useEffect(() => {
    if (!loading && loaded) {
      onCancel();
    }
  }, [loading, loaded, onCancel]);
  return (
    <Dialog open={visible} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Keepler Message Template</DialogTitle>
      <DialogContent>
        <TextField
          className="md:w-96 w-full"
          id="outlined-multiline-static"
          label="New message"
          multiline
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          rows={4}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={loading} color="primary">
          Discard
        </Button>
        <Button
          disabled={message.length < 5 || loading}
          onClick={() => dispatch(createKeeplerMessage(message))}
          color="primary"
          autoFocus
        >
          {loading && <CircularProgress size={18} />}
          {!loading && "Save"}
        </Button>
      </DialogActions>
      <div className="w-full h-full"></div>
    </Dialog>
  );
}

CreateMessageDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Avatar, Typography, Card, InputBase, IconButton, Fab, CircularProgress } from "@material-ui/core";

import { fetchTemplates } from "../../../store/templates/actions";
import { templateSelector } from "../../../store/templates/selector";

export const List = () => {
  const dispatch = useDispatch();
  const { items, loading, error } = useSelector(templateSelector);

  useEffect(() => {
    if (items.length === 0) {
      dispatch(fetchTemplates());
    }
  }, [dispatch, items]);

  return (
    <div className="h-full w-full p-2 pointer">
      {items.map(({ id, title, subTitle }, index) => (
        <Card key={id} className="p-3 m-2 bg-gradient-to-tr from-gray-50 via-indigo-50 to-indigo-100">
          <Typography className="font-medium">{title}</Typography>
          <Typography className="text-sm text-gray-600 mt-1">{subTitle}</Typography>
        </Card>
      ))}
    </div>
  );
};

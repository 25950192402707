import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button, Avatar } from "@material-ui/core";
import { DataGrid, GridColDef } from "@material-ui/data-grid";

import { formatBirthday, formatPhone } from "../../../utils/formatter";

import { fetchUsers } from "../../../store/users/actions";
import { userStateSelector } from "../../../store/users/selector";

const columns: GridColDef[] = [
  {
    field: "profileImage",
    headerName: "Image",
    renderCell: (params) => (
      <Link to={`/users/${params.row.id}`}>
        <Avatar className="cursor-pointer" src={params.row.profileImage}></Avatar>
      </Link>
    ),
  },
  { field: "fullName", headerName: "Name", width: 180 },
  { field: "phone", headerName: "Phone", width: 180, valueGetter: (params: any) => formatPhone(params.row.phone) },
  {
    field: "addr",
    headerName: "Address",
    width: 200,
    valueGetter: (params: any) => params.row.address.address || 'Unknown',
  },
  { field: "birthday", headerName: "Birthday", width: 120, valueGetter: (params: any) => formatBirthday(params.row.birthday) },
  {
    field: "createdAt",
    headerName: "Register Date",
    width: 140,
    valueGetter: (params: any) => formatBirthday(params.row.createdAt)
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => (
      <Button
        color="primary"
        onClick={() => {
          console.log({ params });
        }}
      >
        Active
      </Button>
    ),
  },
];

export const List = () => {
  const dispatch = useDispatch();
  const { items, loading, error } = useSelector(userStateSelector);

  useEffect(() => {
    if (items.length === 0) {
      dispatch(fetchUsers());
    }
  }, [dispatch, items]);

  return (
    <div className="h-full w-full p-2 pointer">
      <DataGrid
        columns={columns}
        loading={loading}
        error={error}
        rows={items}
        pageSize={15}
        disableSelectionOnClick={true}
      />
    </div>
  );
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";

import LogInPage from "../pages/LogInPage";
import PrivateRouter from "./PrivateRouter";

import { history } from "../store";
import { authorizingSelector, isLoggedInSelector } from "../store/system/selector";
import { getAuthToken } from "../config";
import { fetchUser } from "../store/system/actions";
import { AppLoader } from "../components";

const Router: React.FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const authorizing = useSelector(authorizingSelector);
  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      dispatch(fetchUser());
    }
  }, [isLoggedIn, dispatch]);

  if (authorizing) {
    return <AppLoader />;
  }
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/login" component={LogInPage} />
        {!authorizing && isLoggedIn && <PrivateRouter />}
        {!authorizing && !isLoggedIn && <Redirect to="/login" />}
      </Switch>
    </ConnectedRouter>
  );
};

export default Router;

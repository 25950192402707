import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./App.css";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";

import configureStore from "./store";
import BaseRouter from "./router";

const App: React.FC = () => {
  const [rootStore, setRootStore] = useState<any | undefined>(undefined);

  useEffect(() => {
    const initStore = async () => {
      const { store, persistor } = await configureStore();
      setRootStore({ store, persistor });
    };
    initStore();
  }, []);

  if (!rootStore) {
    return null;
  }
  return (
    <Provider store={rootStore.store}>
      <PersistGate loading={null} persistor={rootStore.persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BaseRouter />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;

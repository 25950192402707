import React from "react";
import PropTypes, { InferProps } from "prop-types";

export function InputOptions({ items }: InferProps<typeof InputOptions.propTypes>) {
  return (
    <div className="flex flex-wrap">
      {items.map((item, index) => (
        <div key={index} className="text-xs text-yellow-700 m-0.5 px-1 bg-gray-200 rounded">
          {item}
        </div>
      ))}
    </div>
  );
}

InputOptions.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

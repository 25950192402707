import React, { useState } from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { MessageTab } from "./tabs/MessageTab";
import { CategoryTab } from "./tabs/CategoryTabs";

export const ReflectionTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="flex flex-col h-full w-full">
      <AppBar position="static">
        <Tabs value={tabIndex} onChange={(event: any, value) => setTabIndex(value)} aria-label="simple tabs example">
          <Tab label="Messages" />
          <Tab label="Categories" />
        </Tabs>
      </AppBar>
      <div className="flex flex-col flex-1 w-full">
        <MessageTab active={tabIndex === 0} />
        <CategoryTab active={tabIndex === 1} />
      </div>
    </div>
  );
};

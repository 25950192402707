import { all, call, fork, put, take } from "redux-saga/effects";
import { fetchUsersApi } from "../../apiService";

import { FAIL, START, SUCCESS } from "../common";
import { FETCH_USERS, UsersPayload } from "./actions";

function* getUser() {
  while (true) {
    yield take(FETCH_USERS + START);
    try {
      const data: UsersPayload = yield call(fetchUsersApi, {});
      yield put({ type: FETCH_USERS + SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: FETCH_USERS + FAIL, payload: error.response.data });
    }
  }
}

export function* usersSagas() {
  yield all([fork(getUser)]);
}

import React from "react";
import { Redirect, Route, Switch } from "react-router";

import { List } from "./List";

const TemplateRouter = () => {
  return (
    <Switch>
      <Route exact path="/templates" component={List} />
      <Redirect to="/templates" />
    </Switch>
  );
};

export default TemplateRouter;

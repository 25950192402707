import React, { useEffect, useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Card, InputBase, IconButton, Fab, CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import DeleteIcon from "@material-ui/icons/Delete";

import { ConfirmDialog, CreateMessageDialog } from "../../../components";

import { deleteKeeplerMessage, fetchKeeplerMessages, updateKeeplerMessage } from "../../../store/reflection/actions";
import { keeplerMessageSelector } from "../../../store/reflection/selector";
import { KeeplerMessage } from "../../../interfaces";

export function MessageTab({ active }: InferProps<typeof MessageTab.propTypes>) {
  const dispatch = useDispatch();
  const { items: initialItems, loading, itemStatus } = useSelector(keeplerMessageSelector);
  const [items, setItems] = useState(initialItems);
  const [showDelete, setShowDelete] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [selectedItem, setSelectedItem] = useState<undefined | KeeplerMessage>();
  useEffect(() => {
    dispatch(fetchKeeplerMessages());
  }, [dispatch]);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const handleChange = (text: string, index: number) => {
    const newList = items.map((item, i) => (index === i ? { ...item, message: text } : item));
    setItems(newList);
  };

  const resetChanges = (index: number) => {
    const newList = items.map((item, i) => (index === i ? { ...item, message: initialItems[index].message } : item));
    setItems(newList);
  };

  const deleteMessage = (index: number) => {
    setSelectedItem(items[index]);
    setShowDelete(true);
  };

  const updateMessage = (item: KeeplerMessage) => {
    dispatch(updateKeeplerMessage({ id: item.id, message: item.message }));
  };

  return (
    <Card role="tabpanel" className={`p-3 ${active ? "flex" : "hidden"} flex-1 flex-col m-2 relative`}>
      {loading && <CircularProgress className="opacity-50 absolute left-0 right-0 m-auto" />}
      {items.map((item, index) => (
        <Card key={item.id} className="flex h-16 p-4 mb-4 items-center relative">
          <Typography className="mr-2">{index + 1}.</Typography>
          <InputBase
            className="flex-1"
            value={item.message}
            onChange={(event) => handleChange(event.target.value, index)}
          />
          {initialItems[index] && initialItems[index].message !== item.message && (
            <>
              <IconButton onClick={() => resetChanges(index)}>
                <UndoIcon color="action" />
              </IconButton>
              <IconButton onClick={() => updateMessage(item)}>
                <SaveIcon color="action" />
              </IconButton>
            </>
          )}
          {initialItems[index] && initialItems[index].message === item.message && (
            <IconButton onClick={() => deleteMessage(index)}>
              <DeleteIcon color="action" />
            </IconButton>
          )}
          {itemStatus.loading && itemStatus.itemId === item.id && (
            <div className="w-full h-full left-0 absolute bg-black	bg-opacity-5 flex items-center justify-center">
              <CircularProgress size={16} />
            </div>
          )}
        </Card>
      ))}
      <ConfirmDialog
        visible={showDelete}
        title="Confirm"
        message="Are you sure you want to delete this message?"
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          if (selectedItem?.id) {
            dispatch(deleteKeeplerMessage(selectedItem.id));
            setSelectedItem(undefined);
          }
        }}
      />
      <CreateMessageDialog
        visible={showCreate}
        onCancel={() => setShowCreate(false)}
        onConfirm={() => setShowCreate(false)}
      />
      <Fab color="primary" aria-label="add" className="absolute bottom-10 right-10" onClick={() => setShowCreate(true)}>
        <AddIcon />
      </Fab>
    </Card>
  );
}

MessageTab.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

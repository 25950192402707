import produce from "immer";
import { handleActions } from "redux-actions";

import { UsersState } from "../../interfaces";
import { FAIL, START, SUCCESS } from "../common";
import { FETCH_USERS } from "./actions";

const initialState: UsersState = {
  items: [],
  loading: false,
  page: 1,
  limit: 20,
  total: 0,
  error: null,
};

export const usersReducer = handleActions<UsersState, any>(
  {
    [FETCH_USERS + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
      }),
    [FETCH_USERS + SUCCESS]: (state, { payload: { data, page, limit, total } }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.items = data;
        draft.page = page;
        draft.limit = limit;
        draft.total = total;
        draft.error = null;
      }),
    [FETCH_USERS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.error = payload;
      }),
  },
  initialState
);

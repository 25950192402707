import { User } from "../../interfaces";
import axiosClient from "../axiosClient";

export const fetchLoginApi = async (email: string, password: string) => {
  const { data } = await axiosClient.post<{ token: string; user: User }>("/auth/admin/login", { email, password });
  return data;
};

export const fetchUserApi = async () => {
  const { data } = await axiosClient.get<User>("/admin/users/me");
  return data;
};

import { createAction } from "redux-actions";
import { START } from "../common";

export const FETCH_KEEPLER_MESSAGES = "FETCH_KEEPLER_MESSAGES";
export const CREATE_KEEPLER_MESSAGE = "CREATE_KEEPLER_MESSAGE";
export const RESET_ITEM_STATUS = "RESET_ITEM_STATUS";
export const DELETE_KEEPLER_MESSAGE = "DELETE_KEEPLER_MESSAGE";
export const UPDATE_KEEPLER_MESSAGE = "UPDATE_KEEPLER_MESSAGE";
export const FETCH_REFLECTION_CATEGORY = "FETCH_REFLECTION_CATEGORY";

export const resetItemProgress = createAction(RESET_ITEM_STATUS);
export const fetchKeeplerMessages = createAction(FETCH_KEEPLER_MESSAGES + START);
export const createKeeplerMessage = createAction(CREATE_KEEPLER_MESSAGE + START);
export const deleteKeeplerMessage = createAction(DELETE_KEEPLER_MESSAGE + START);
export const updateKeeplerMessage = createAction(UPDATE_KEEPLER_MESSAGE + START);
export const fetchReflectionCategory = createAction(FETCH_REFLECTION_CATEGORY + START);

import axiosClient from "../axiosClient";

export const fetchMessagesApi = async () => {
  const { data } = await axiosClient.get("admin/keepler-messages");
  return data;
};

export const createMessagesApi = async (message: string) => {
  const { data } = await axiosClient.post("admin/keepler-messages", { message });
  return data;
};

export const updateMessagesApi = async (id: string, message: string) => {
  const { data } = await axiosClient.put(`admin/keepler-messages/${id}`, { message });
  return data;
};

export const deleteMessagesApi: any = async (id: string) => {
  const { data } = await axiosClient.delete<any>(`admin/keepler-messages/${id}`);
  return data;
};

export const fetchCategoriesApi = async () => {
  const { data } = await axiosClient.get("admin/categories");
  return data;
};
